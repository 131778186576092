import { memo, useEffect, useRef, useState } from "react";
import { useAppSelector } from "../../Store";
import { allMessagesStore } from "../../Store/Slices/socket/AllMessagesSlice";
import {
  AppRenderAudio,
  AppRenderCard,
  AppRenderCtaButtons,
  AppRenderFile,
  AppRenderFlowButtons,
  AppRenderImage,
  AppRenderInfo,
  AppRenderLiveButtons,
  AppRenderReturningSession,
  AppRenderSlider,
  AppRenderText,
  AppRenderTypingIndicator,
  AppRenderVideo,
} from "../appComponents/RenderComponents";
import { MESSAGE_TYPES } from "../utils/Enum";
import { useSelector } from "react-redux";
import { botInfoStore } from "../../Store/Slices/socket/BotInfoSlice";
import AppRenderTypingIndicatorForLandingPgaeBot from "../appComponents/RenderComponents/AppRenderTypingIndicatorForLandingPgaeBot";

interface Props {
  [otherProps: string]: any;
}

const MessagesScreen: React.FC<Props> = () => {
  const [removeDiv, setRemoveDive] = useState(true);
  //redux
  const { allMessages, sessionId } = useAppSelector(allMessagesStore);
  const { isBotSetToFullScreen } = useSelector(botInfoStore);
  const messageRefs = useRef<any>({});

  useEffect(() => {
    console.log(allMessages, "allMessages");
    if (allMessages.length > 0 && isBotSetToFullScreen) {
      // Find the last user message in the array
      const lastUserMessage = allMessages
        .slice()
        .reverse()
        .find((message) => message.isUser); // Check if the message is from the user

      if (lastUserMessage) {
        const lastMessageId = lastUserMessage.value;

        if (messageRefs.current[lastMessageId]) {
          console.log(
            messageRefs.current[lastMessageId],
            "messageRefs.current[lastMessageId]"
          );
          // Scroll to the last user's message
          const element = messageRefs.current[lastMessageId];
          if (element) {
            const parentElement = element.parentElement; // Assuming the parent is the scrollable container
            if (parentElement) {
              const offset = 16; // Adjust this value to add space from the top
              // Scroll to the topmost position of the container minus offset
              parentElement.scrollTo({
                top: Math.max(0, element.offsetTop - offset),
                behavior: "smooth",
              });
            }
          }
        }
      }
    }
  }, [allMessages, isBotSetToFullScreen]);

  //scroll to bottom
  const messagesEndRef = useRef<any>();

  useEffect(() => {
    if (isBotSetToFullScreen) {
      if (messagesEndRef.current) {
        // messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
        setTimeout(() => {
          messagesEndRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
            inline: "nearest",
          });
        }, 1000);
      }
    }
  }, []);

  useEffect(() => {
    if (!isBotSetToFullScreen) {
      if (messagesEndRef.current) {
        // messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;

        messagesEndRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    }
  }, [isBotSetToFullScreen, allMessages]);

  //render returning user session confirmation message
  if (allMessages?.length === 0 && sessionId?.length > 0)
    return (
      <>
        <AppRenderReturningSession />
        <div ref={messagesEndRef} />
      </>
    );

  return (
    <>
      {allMessages &&
        allMessages?.length > 0 &&
        allMessages?.map((singleMsg: any, index: number) => {
          //* TEXT
          if (singleMsg?.type === MESSAGE_TYPES.TEXT) {
            return (
              <AppRenderText
                isFullScreen={isBotSetToFullScreen}
                ref={
                  singleMsg?.isUser
                    ? (el: any) => {
                        return (messageRefs.current[singleMsg.value] = el);
                      }
                    : undefined
                }
                userMessage={singleMsg?.isUser ? true : false}
                key={index.toString()}
                time={singleMsg?.time}
                readStatus={singleMsg?.readStatus || "delivered"}
              >
                {singleMsg?.text || singleMsg?.value}
              </AppRenderText>
            );
          }

          //* INFO Alert
          if (singleMsg?.type === MESSAGE_TYPES.INFO) {
            return (
              <AppRenderInfo
                key={index.toString()}
                userMessage={singleMsg?.isUser ? true : false}
                time={singleMsg?.time}
                readStatus={singleMsg?.readStatus || "delivered"}
              >
                {singleMsg?.text || singleMsg?.value}
              </AppRenderInfo>
            );
          }

          // Buttons
          if (singleMsg?.type === MESSAGE_TYPES.BUTTON) {
            return (
              <AppRenderCard
                userMessage={singleMsg?.isUser ? true : false}
                key={index.toString()}
                item={singleMsg?.value}
                time={singleMsg?.time}
                readStatus={singleMsg?.readStatus || "delivered"}
              />
            );
          }

          //* VIDEO
          if (singleMsg?.type === MESSAGE_TYPES.VIDEO) {
            return (
              <AppRenderVideo
                userMessage={singleMsg?.isUser ? true : false}
                key={index.toString()}
                src={singleMsg?.value?.url || ""}
                alt={singleMsg?.value?.text || ""}
                time={singleMsg?.time}
                readStatus={singleMsg?.readStatus || "delivered"}
              />
            );
          }
          //* AUDIO
          if (singleMsg?.type === MESSAGE_TYPES.AUDIO) {
            return (
              <AppRenderAudio
                userMessage={singleMsg?.isUser ? true : false}
                key={index.toString()}
                src={singleMsg?.value?.url || ""}
                alt={singleMsg?.value?.text || ""}
                time={singleMsg?.time}
                readStatus={singleMsg?.readStatus || "delivered"}
              />
            );
          }

          //* IMAGE
          if (singleMsg?.type === MESSAGE_TYPES.IMAGE) {
            return (
              <AppRenderImage
                userMessage={singleMsg?.isUser ? true : false}
                key={index.toString()}
                src={singleMsg?.value?.url || ""}
                alt={singleMsg?.value?.text || ""}
                time={singleMsg?.time}
                readStatus={singleMsg?.readStatus || "delivered"}
              />
            );
          }

          //* FILE
          if (
            singleMsg?.type === MESSAGE_TYPES.FILE ||
            singleMsg?.type === MESSAGE_TYPES.DOCUMENT
          ) {
            return (
              <AppRenderFile
                userMessage={singleMsg?.isUser ? true : false}
                key={index.toString()}
                src={singleMsg?.value?.url || ""}
                name={singleMsg?.value?.fileName || "File"}
                alt={singleMsg?.value?.text || ""}
                time={singleMsg?.time}
                readStatus={singleMsg?.readStatus || "delivered"}
              />
            );
          }

          //* CAROUSEL
          if (singleMsg?.type === MESSAGE_TYPES.CAROUSEL) {
            return (
              <AppRenderSlider
                userMessage={singleMsg?.isUser ? true : false}
                items={singleMsg?.value}
                key={index.toString()}
                time={singleMsg?.time}
                readStatus={singleMsg?.readStatus || "delivered"}
              />
            );
          }

          return null;
        })}

      {isBotSetToFullScreen ? (
        <AppRenderTypingIndicatorForLandingPgaeBot />
      ) : (
        <AppRenderTypingIndicator />
      )}
      <div ref={messagesEndRef} />
    </>
  );
};

export default memo(MessagesScreen);

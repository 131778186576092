import { IconButton } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { withTheme } from "styled-components";
import { useAppDispatch, useAppSelector } from "../../Store";
import { useBotConfigStore } from "../../Store/Slices/ChatbotSlices/BotConfigSlice";
import { allMessagesStore } from "../../Store/Slices/socket/AllMessagesSlice";
import {
  botInfoStore,
  setOpenStatus,
} from "../../Store/Slices/socket/BotInfoSlice";
import { socketStatusStore } from "../../Store/Slices/socket/SocketSlice";
import {
  StyledWidgetHeader,
  WidgetHeaderBrand,
  WidgetHeaderBrandTitle,
  WidgetHeaderRight,
  WidgetHeaderStatus,
} from "../Styles/StyledScreens";
import {
  AppLanguageSelector,
  AppRenderNotificationMenu,
  AppRenderQuickMenu,
} from "../appComponents/Features";
import { AppMaterialIcons } from "../appComponents/Icons";
import AppEndChatFeedback from "../appComponents/UtilsComponents/AppEndChatFeedback";
import { PreviewType } from "../utils/Enum";
import AppRenderQuickMenuHeader from "../appComponents/Features/AppRenderQuickMenuHeader";
import AppRestartChat from "../appComponents/UtilsComponents/AppRestartChat";

interface Props {
  theme?: any;
}

const Header: React.FC<Props> = (props) => {
  const dispatch = useAppDispatch();
  const { widgetPreviewType, isBotSetToFullScreen } = useSelector(botInfoStore);
  const { botConfig } = useSelector(useBotConfigStore);
  const { typingStatus } = useAppSelector(allMessagesStore);

  const handleCloseChat = () => {
    dispatch(setOpenStatus(false));
  };

  return (
    <StyledWidgetHeader theme={props.theme}>
      <AppRenderQuickMenuHeader />
      <WidgetHeaderBrand>
        <img
          src={botConfig.config?.company_logo?.source}
          alt={botConfig?.chatbot?.name}
        />
        <div className="titleBox">
          <WidgetHeaderBrandTitle>
            {botConfig?.chatbot?.name}
          </WidgetHeaderBrandTitle>
          <WidgetHeaderStatus>{typingStatus || ""}</WidgetHeaderStatus>
        </div>
      </WidgetHeaderBrand>
      <WidgetHeaderRight>
        <AppLanguageSelector />
        <AppRenderNotificationMenu />
        {isBotSetToFullScreen ? null : (
          <IconButton
            aria-label="minimize chat"
            onClick={() => {
              handleCloseChat();
            }}
            disabled={
              widgetPreviewType === PreviewType.getaWidgetPreview ? true : false
            }
          >
            <AppMaterialIcons
              iconName="ExpandMore"
              style={{
                color: props.theme?.components?.header.text.color,
                fontSize: "26px",
              }}
            />
          </IconButton>
        )}
        {widgetPreviewType === PreviewType.getaWidgetPreview ||
        isBotSetToFullScreen ? null : (
          <AppEndChatFeedback />
        )}
        {isBotSetToFullScreen ? <AppRestartChat /> : null}
      </WidgetHeaderRight>
    </StyledWidgetHeader>
  );
};

export default withTheme(Header);
